<template>
  <div class="promiseLetter">
    <div class="promiseLetter-c">
      <div class="promiseLetter-title">{{ postData.promiseTitle }}</div>
      <div class="letter">
        <div class="letter-main" v-html="postData.promiseContent"></div>
      </div>
      <div class="sign">
        <div class="title">承诺书签名</div>
        <div class="sign-main" ref="canvasHW">
          <signCanvas
            ref="esign"
            :width="800"
            :height="354"
            :isCrop="isCrop"
            :lineWidth="lineWidth"
            :lineColor="lineColor"
            :bgColor.sync="bgColor"
          />
        </div>
        <div class="opera">
          <span @click="handleReset">清空</span>
        </div>
      </div>
      <div class="btn" @click="handleGenerate">提交承诺书</div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { toRegister, gloabalCount } from "@/utils/common.js";
import { base64toFile } from "@/utils/utils.js";
import signCanvas from "@/components/bussiness/signCanvas";
import { rubTopicInfoUrl, signImgUrl } from "./api.js";
var moment = require("moment");
export default {
  name: "promiseLetter",
  data() {
    return {
      lineWidth: 6,
      lineColor: "#000000",
      bgColor: "#eeeeee",
      resultImg: "",
      isCrop: false,
      postData: {
        promiseTitle: "",
        promiseContent: "",
      },
      uploadUrl: `${window.ORIGIN}/gateway/blade-resource/oss/endpoint/put-file`,
      signImageUrl: "",
    };
  },
  components: {
    signCanvas,
  },
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {
    // gloabalCount("", 20, 1);
    this.getIndexData();
  },
  methods: {
    async uploadImg(file) {
      var formData = new FormData();
      formData.append("file", file);
      let res = await this.$axios.post(`${this.uploadUrl}`, formData);
      if (res.code == 200) {
        this.signImageUrl = res.data.link;
        this.postSignData();
      } else {
      }
    },
    async postSignData() {
      let params = {
        houseId: this.roomId || undefined,
        userId: this.userId,
        signImageUrl: this.signImageUrl,
        topicId: this.postData.id,
      };
      let res = await this.$axios.post(signImgUrl, this.$qs.stringify(params));
      if (res.code === 200) {
        this.$toast({ message: "提交成功", duration: 2000 });
        wx.miniProgram.navigateTo({
          url: `/pages/index/rubIndex`,
        });
      } else {
      }
    },
    handleReset() {
      this.$refs.esign.reset();
    },

    handleGenerate() {
      this.$refs.esign
        .generate()
        .then((res) => {
          this.resultImg = res;
          console.log(base64toFile(this.resultImg), "009");
          this.uploadImg(base64toFile(this.resultImg));
        })
        .catch((err) => {
          alert(err); // 画布没有签字时会执行这里 'Not Signned'
        });
    },

    async getIndexData() {
      let res = await this.$axios.get(rubTopicInfoUrl, {
        params: {
          houseId: this.roomId || undefined,
          userId: this.userId,
        },
      });
      if (res.code === 200) {
        this.postData = res.data;
      } else {
      }
    },
  },
};
</script>
<style lang="less" scoped>
.promiseLetter {
  min-height: 100vh;
  padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .promiseLetter-c {
    padding: 16px 30px;
    .letter {
    }
    .promiseLetter-title {
      font-size: 44px;
      padding: 32px 0 24px;
      font-weight: 600;
      color: #1a1c34;
      line-height: 60px;
      text-align: center;
    }
    .sign-main {
      width: 100%;
    }
    .sign {
      margin-top: 66px;
      .title {
        font-size: 40px;
        text-align: center;
        font-weight: 600;
        color: #343332;
        line-height: 56px;
      }
      .img {
        width: 100%;
        img {
          width: 100%;
        }
      }
      .opera {
        text-align: right;
        padding-right: 20px;
      }
    }
    .btn {
      width: 598px;
      height: 80px;
      background: #2e5da2;
      border-radius: 40px;
      font-size: 40px;
      text-align: center;
      font-weight: 400;
      color: #ffffff;
      line-height: 80px;
      margin: 42px auto 0;
    }
  }
}
</style>
<style lang="less">
.promiseLetter {
}
</style>
